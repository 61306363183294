import logo from './logo.svg';
import './App.css';

import { useState, useEffect } from 'react';
import QRCode from "react-qr-code";
var App = () => {

  const [leftKnee, setLeftKnee] = useState("Not defined");
  const [rightKnee, setRightKnee] = useState("Not defined");
  const [url, setUrl] = useState("wss://qrcodepoc-server.bonetag.fr");

  useEffect(() => {
    const ws = new WebSocket(url);
    ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log('connected')
    }
    ws.onmessage = evt => {
      // listen to data sent from the websocket server
      console.log(JSON.parse(evt.data))
      const message = JSON.parse(evt.data);
      if (message.sender == "smartphone") {
        if (message.info) {
          var kneeInfo = message.info;
          if (kneeInfo.leftKnee) {
            setLeftKnee(kneeInfo.leftKnee);
          }
          if (kneeInfo.rightKnee) {
            setRightKnee(kneeInfo.rightKnee);
          }
        }
      }
    }
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Browser Web App
        </p>
      </header>
      <div className="App-content">
        <QRCode value={url} />
        <div className="inputs-item">URL : {url}</div>
        <div className="inputs" >
          <div className="inputs-item">Left Knee : {leftKnee}</div>
          <div className="inputs-item">Right Knee : {rightKnee}</div>
        </div>

      </div>

    </div>
  );
}

export default App;
